<template>
    <NuxtRouteAnnouncer />
    <NuxtLoadingIndicator />
    <main v-cloak>
        <NuxtPage />
    </main>
</template>

<style>
[v-cloak] {
  display: none;
}
body {
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystem--font, Roboto, "Helvetica Neue", sans-serif;
    background: hsl(from ActiveText h calc(s - 30%) l);
    background-color: color-mix(in srgb, Canvas, CanvasText 2.5%);
    color: color-mix(in srgb, CanvasText, Canvas 15%);
    color-scheme: dark light;
}
button {
    padding: 8px;
    border-style: unset;
    border-radius: 8px;
}
</style>